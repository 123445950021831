/* eslint-disable */
import session from './session';

export default {
    getUserList(filter) {
        return session.get(`/admin/v1/user/${filter}`);
    },
    getUser(id) {
        return session.get(`/admin/v1/user/${id}/`);
    },
    deleteUser(userId) {
        return session.delete(`/admin/v1/user/${userId}/`);
    },
    getCurrentUser() {
        return session.get('/admin/v1/user/my/');
    },
    createUser(data) {
        return session.post(`/admin/v1/user/`, data);
    },
    updateUserData(userId, data) {
        return session.patch(`/admin/v1/user/${userId}/`, data );
    },
};