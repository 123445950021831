<script setup>
import {useStore} from "vuex";
import {useLoading} from 'vue-loading-overlay'
import {computed, onMounted, ref} from "vue";
import EmployeesTable from "@/components/tables/EmployeesTable.vue";
import EmployeesInfoPanel from "@/components/panels/EmployeesInfoPanel.vue";
import UserCreateUpdateModal from "@/components/modals/UserCreateUpdateModal.vue";
import PagePaginator from "@/components/layouts/PagePaginator.vue";
import Multiselect from "vue-multiselect";

const fullFilter = ref('')
const showModal = ref(false)

const store = useStore()
const userList = computed(() => store.getters['user/userList'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const statistic = computed(() => store.getters['statistic/employeesStatistic'])
const currentUserData = computed(() => store.getters['user/currentUserData'])
const getUserList = (val) => store.dispatch('user/getUserList', val)
const getEmployeesStatistic = (val) => store.dispatch('statistic/getEmployeesStatistic', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)


const $loading = useLoading({})
const organization = ref(null)
const searchName = ref(null)
const role = ref(null)
const rolesList = ref([
    { filter: 'administrator', name: 'administratord' },
    { filter: 'manager', name: 'manager' },
    { filter: 'employee', name: 'employee' },
])

function getFilteredUserList() {
  const loader = $loading.show({})
  fullFilter.value = `?page=${page.value}`
  if (organization.value) {
    fullFilter.value += `&organization=${organization.value.id}`
  }
  if (role.value) {
    fullFilter.value += `&role=${role.value.filter}`
  }
  if (searchName.value) {
    fullFilter.value += `&name=${searchName.value}`
  }
  getUserList(fullFilter.value)
    .finally(() => {loader.hide()});
  }

const page = ref(1)
function clickCallback(pageNum) {
  page.value = pageNum;
  getFilteredUserList()
}

function closeModal() {
  showModal.value = false
  getEmployeesStatistic()
  getFilteredUserList()
}

onMounted(() => {
    getOrganizationList('')
    getEmployeesStatistic()
    getFilteredUserList()
})
</script>

<template>
  <UserCreateUpdateModal v-model="showModal" v-if="showModal" @close="closeModal()" :user-role="currentUserData.role"/>
  <section class="employees-page">
    <div class="row pb-3">
      <div class="col"><h1>Employees</h1></div>
      <div class="col text-end">
        <button @click="showModal=true" type="button" class="btn btn-primary">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8337 6.83166H6.83366V11.8317H5.16699V6.83166H0.166992V5.16499H5.16699V0.164993H6.83366V5.16499H11.8337V6.83166Z" fill="white"/>
          </svg>
          Add employee
        </button>
      </div>
    </div>
    <EmployeesInfoPanel :data="statistic"/>

    <div class="row pt-5">
      <div class="col-6">
        <div class="row">
          <div class="col">
            <h4>Employees list</h4>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Search by name or email"
                   v-model="searchName"  @input="getFilteredUserList()">
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="rolesList">
              <multiselect
                  placeholder="Role"
                  v-model="role"
                  :options="rolesList"
                  label="name"
                  @close="getFilteredUserList()"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-6">
            <div class="pb-3 form-floating select-wrapper" v-if="organizationList">
              <multiselect
                  placeholder="Organization"
                  v-model="organization"
                  :options="organizationList.results"
                  label="name"
                  @close="getFilteredUserList()"
              >
              </multiselect>
            </div>
          </div>
        </div>

      </div>
    </div>


    <EmployeesTable @update="getFilteredUserList" :user-list="userList" />
    <PagePaginator @change="clickCallback" :page-count="userList.total_pages" />
  </section>
</template>
