import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "@/views/LoginPage";
import DashboardPage from "@/views/DashboardPage";
import AccountSettings from "@/views/AccountSettings";
import EmployeesPage from "@/views/EmployeesPage";
import OrganizationsPage from "@/views/OrganizationsPage";
import DevicesLockers from "@/views/devices/DevicesLockers";
import DevicesLogs from "@/views/devices/DevicesLogs";
import ControlUnits from "@/views/devices/DevicesControlUnits";
import PrepaidAccounts from "@/views/accounts/PrepaidAccounts.vue";
import DevicesNew from "@/views/devices/DevicesNew";
import LocationsPage from "@/views/locations/LocationsPage.vue";
import DevicesDetail from "@/views/devices/DevicesDetail.vue";
import DevicesFirmwares from "@/views/devices/DevicesFirmwares.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/dashboard',
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage,
        meta: {
            title: 'Dashboard - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/account',
        name: 'account',
        component: AccountSettings,
        meta: {
            title: 'Account settings - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/lockers/new',
        name: 'lockers-new',
        component: DevicesNew,
        meta: {
            title: 'Add new locker - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/lockers',
        name: 'lockers',
        component: DevicesLockers,
        meta: {
            title: 'Lockers - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/lockers/:slug',
        name: 'locker',
        component: DevicesDetail,
        meta: {
            title: 'Lockers - Lockerbot admin panel',
        },
    },
    {
        path: '/devices-logs',
        name: 'devices-logs',
        component: DevicesLogs,
        meta: {
            title: 'Devices logs - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/control-units',
        name: 'control-units',
        component: ControlUnits,
        meta: {
            title: 'Control units - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/prepaid-accounts',
        name: 'prepaid-accounts',
        component: PrepaidAccounts,
        meta: {
            title: 'Prepaid accounts - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/employees',
        name: 'employees',
        component: EmployeesPage,
        meta: {
            title: 'Employees - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: OrganizationsPage,
        meta: {
            title: 'Organizations - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/locations',
        name: 'locations',
        component: LocationsPage,
        meta: {
            title: 'Locations - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/firmwares',
        name: 'firmwares',
        component: DevicesFirmwares,
        meta: {
            title: 'Firmwares - Lockerbot admin panel',
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            title: 'Login - Lockerbot admin panel',
        },
    },
    // otherwise redirect to home
    { path: '/:pathMatch(.*)*', redirect: '/dashboard' },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem('token')) {
            next();
            return;
        }
        next('/login');
    } else {
        next();
    }
});

export default router
