import session from './session';

export default {
    login(email, password) {
        return session.post('/admin/v1/user/login/', { email, password });
    },
    changeAccountPassword(password, password1, password2) {
        return session.post('/admin/v1/user/change-password/', { password, password1, password2 });
    },
};
