/* eslint-disable */
import session from './session';

export default {
    getCellSizeList(filter) {
        return session.get(`/admin/v1/cell_size/${filter}`);
    },
    getCellSize(id) {
        return session.get(`/admin/v1/cell_size/${id}/`);
    },
    createCellSize(data) {
        return session.post(`/admin/v1/cell_size/`, data);
    },
    updateCellSize(id, data) {
        return session.patch(`/admin/v1/cell_size/${id}/`, data );
    },
    deleteCellSize(id) {
        return session.delete(`/admin/v1/cell_size/${id}/`);
    },
};