/* eslint-disable */
import session from './session';

export default {
    getCellFirmwareList(filter) {
        return session.get(`/admin/v1/firmware/cell/${filter}`);
    },
    getHeadFirmwareList(filter) {
        return session.get(`/admin/v1/firmware/head/${filter}`);
    },
    getFirmware(firmwareType, id) {
        return session.get(`/admin/v1/firmware/${firmwareType}/${id}/`);
    },
    deleteFirmware(firmwareType, id) {
        return session.delete(`/admin/v1/firmware/${firmwareType}/${id}/`);
    },
    createFirmware(firmwareType, data) {
        session.defaults.headers['Content-Type'] = 'multipart/form-data';
        return session.post(`/admin/v1/firmware/${firmwareType}/`, data);
    },
    updateFirmware(firmwareType, id, data) {
        session.defaults.headers['Content-Type'] = 'multipart/form-data';
        return session.patch(`/admin/v1/firmware/${firmwareType}/${id}/`, data );
    },
};