/* eslint-disable */
import session from './session';

export default {
    getRegionList(filter) {
        return session.get(`/admin/v1/region/${filter}`);
    },
    getRegion(id) {
        return session.get(`/admin/v1/region/${id}/`);
    },
    deleteRegion(id) {
        return session.delete(`/admin/v1/region/${id}/`);
    },
    createRegion(data) {
        return session.post(`/admin/v1/region/`, data);
    },
    updateRegion(id, data) {
        return session.patch(`/admin/v1/region/${id}/`, data );
    },
};