<script setup>
import {useStore} from "vuex";
import {computed, defineEmits, defineProps, ref} from "vue";
import {successNotify, errorNotify, prettifyDate, prettifyDateTime} from "@/mixins";
import PrepaidAccountCreateUpdateModal from "@/components/modals/account/PrepaidAccountCreateUpdateModal.vue";
import TableMenu from "@/components/layouts/TableMenu.vue";

const emit = defineEmits(['update'])
defineProps({
  prepaidAccountList: Object
})

const store = useStore()
const instanceDetail = computed(() => store.getters['prepaid_account/prepaidAccountData'])
const deleteInstanceError = computed(() => store.getters['prepaid_account/prepaidAccountDeleteError'])
const getInstanceError = computed(() => store.getters['prepaid_account/prepaidAccountDataError'])
const currentUserData = computed(() => store.getters['user/currentUserData'])
const deleteInstance = (val) => store.dispatch('prepaid_account/deletePrepaidAccount', val)
const getInstance = (val) => store.dispatch('prepaid_account/getPrepaidAccount', val)

const showModal = ref(false)
function openModal(id) {
  getInstance(id)
      .then(() => {if (getInstanceError.value) {errorNotify(getInstanceError.value)}})
      .finally(() => { showModal.value = true })
}
function closeModal() {
  showModal.value = false
  emit('update')
}
function del(id) {
  if(confirm("Do you really want to delete this prepaid account?")) {
    deleteInstance(id)
      .then(() => {
        if (!deleteInstanceError.value) { successNotify("Prepaid account was successfully deleted!")}
        else {errorNotify(deleteInstanceError.value)}
      })
      .finally(() => { emit('update'); })
  }
}
</script>

<template>
  <PrepaidAccountCreateUpdateModal v-model="showModal" v-if="showModal" @close="closeModal()" :instance="instanceDetail" />
  <table class="table">
    <thead>
    <th class="first-el">Device</th>
    <th>Serial number</th>
    <th v-if="currentUserData.role === 'administrator'">Balance</th>
    <th v-if="currentUserData.role === 'administrator'">Prepaid hours</th>
    <th>Expiration</th>
    <th>Activation</th>
    <th>Created</th>
    <th v-if="currentUserData.role === 'administrator'">Organization</th>
    <th></th>
    </thead>
    <tbody>
    <tr v-for="instance in prepaidAccountList.results" :key="instance.sn">
      <th v-if="instance.device">{{ instance.device.name }}</th>
      <th v-else>Any {{ instance.organization.name }} device</th>

      <th class="first-el">{{ instance.sn }}</th>

      <th v-if="currentUserData.role === 'administrator' && instance.balance == null">
        <div class="balance-badge balance-unlimited">Unlimited</div></th>
      <th v-else-if="currentUserData.role === 'administrator' && instance.balance === 0">
        <div class="balance-badge badge-warning">{{instance.balance}} credits</div></th>
      <th v-else-if="currentUserData.role === 'administrator'">
        <div class="balance-badge balance">{{instance.balance}} credits</div></th>

      <th v-if="currentUserData.role === 'administrator' && instance.prepaid_hours == null">
        <div class="balance-badge balance-unlimited">Unlimited</div></th>
      <th v-else-if="currentUserData.role === 'administrator'">
        <div class="balance-badge badge-success">{{ instance.prepaid_hours }} hour</div></th>

      <th v-if="!instance.expiration_date">Unlimited</th>
      <th v-else >{{ prettifyDate(instance.expiration_date) }}</th>

      <th v-if="instance.activation_date">{{ prettifyDateTime(instance.activation_date) }}</th>
      <th v-else>Not activated</th>

      <th>{{ prettifyDate(instance.created_at) }}</th>
      <th v-if="currentUserData.role === 'administrator'">{{ instance.organization.name }}</th>
      <th>
        <TableMenu @open-modal="openModal(instance.sn)" @del="del(instance.sn)"/>
      </th>
    </tr>
    </tbody>
  </table>
</template>
