/* eslint-disable */
import session from './session';

export default {
    getLogList(filter) {
        return session.get(`/admin/v1/device_log/${filter}`);
    },
    getLog(logId) {
        return session.get(`/admin/v1/device_log/${logId}/`);
    },
    deleteLog(logId) {
        return session.delete(`/admin/v1/device_log/${logId}/`);
    },
};