/* eslint-disable */
import session from './session';

export default {
    getPrepaidAccountList(filter) {
        return session.get(`/admin/v1/prepaid_account/${filter}`);
    },
    getPrepaidAccount(id) {
        return session.get(`/admin/v1/prepaid_account/${id}/`);
    },
    createPrepaidAccount(data) {
        return session.post(`/admin/v1/prepaid_account/`, data);
    },
    createPrepaidAccountCodes(data) {
        return session.post(`/admin/v1/prepaid_account/codes/`, data);
    },
    updatePrepaidAccount(id, data) {
        return session.put(`/admin/v1/prepaid_account/${id}/`, data );
    },
    deletePrepaidAccount(id) {
        return session.delete(`/admin/v1/prepaid_account/${id}/`);
    },
};