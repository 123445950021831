<script setup>
import SmallCard from "@/components/panels/SmallCard";
import {computed, defineProps} from "vue";
import {useStore} from "vuex";

const store = useStore()
const props = defineProps({data: { required: true }});
const currentUserData = computed(() => store.getters['user/currentUserData'])


</script>

<template>
  <section class="info-panel pt-3">
    <div class="row h-50">
      <div v-if="currentUserData.role === 'administrator'" class="col-3">
        <SmallCard :count="props.data.balances" :icon-folder="'prepaid_accounts'" :icon-name="'balances'" :name="'Total balances'"/>
      </div>
      <div v-if="currentUserData.role === 'administrator'" class="col-3">
        <SmallCard :count="props.data.hours" :icon-folder="'prepaid_accounts'" :icon-name="'unlimited'" :name="'Total prepaid hours'"/>
      </div>
      <div class="col-3">
        <SmallCard :count="props.data.accounts" :icon-folder="'prepaid_accounts'" :icon-name="'accounts'" :name="'Accounts'"/>
      </div>
      <div class="col-3">
        <SmallCard :count="props.data.active" :icon-folder="'prepaid_accounts'" :icon-name="'active'" :name="'Active account'"/>
      </div>
    </div>
  </section>
</template>

<style scoped>
</style>