/* eslint-disable */
import session from './session';

export default {
    getLockerList(filter) {
        return session.get(`/admin/v1/device/${filter}`);
    },
    getLocker(id) {
        return session.get(`/admin/v1/device/${id}/`);
    },
    createLocker(data) {
        return session.post(`/admin/v1/device/`, data);
    },
    updateLocker(id, data) {
        return session.patch(`/admin/v1/device/${id}/`, data );
    },
    deleteLocker(id) {
        return session.delete(`/admin/v1/device/${id}/`);
    },
    createNewKey(id) {
        return session.get(`/admin/v1/device/${id}/new_key/`);
    },
};