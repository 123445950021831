<script setup>
import { useStore } from "vuex"
import {computed, defineEmits, defineProps, onMounted, ref, toRaw} from "vue"
import { successNotify, errorNotify } from '@/mixins.js'
import MainModal from "@/components/layouts/MainModal"
import Multiselect from "vue-multiselect"

const store = useStore()
const createInstanceError = computed(() => store.getters['user/createUserError'])
const updateInstanceError = computed(() => store.getters['user/updateUserError'])
const organizationList = computed(() => store.getters['organization/organizationList'])
const createInstance = (val) => store.dispatch('user/createUser', val)
const updateInstance = (val) => store.dispatch('user/updateUserData', val)
const getOrganizationList = (val) => store.dispatch('organization/getOrganizationList', val)

const emit = defineEmits(['update', 'close'])
const props = defineProps({instance: Object, userRole: {required: true}})

const organizations = ref([])
const role = ref('')
const roleChoices = [{role: 'manager'}, {role: 'employee'}, {role: 'administrator'}]
const instanceData = {
  first_name: '',
  email: '',
  role: '',
  organizations: [],
  password: ''
}

function prepareInstanceData() {
  instanceData.role = role.value.role
  if (organizations.value) {
     organizations.value.forEach( (element) => {
      instanceData.organizations.push(element.id)
    })
  }
}
function instanceCreate() {
  prepareInstanceData()
  createInstance(instanceData)
    .then(() => {
      if (!createInstanceError.value) { successNotify("Employee added successfully!") }
      else { errorNotify(createInstanceError.value) }
    })
    .finally(() => { emit('close') })
}
function instanceUpdate() {
  prepareInstanceData()
  if (!instanceData.password.value) {
    delete instanceData.password
  }
  let newData = {data: instanceData, id: props.instance.id }
  updateInstance(newData)
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Employee updated successfully!") }
        else {errorNotify(updateInstanceError.value)}
      })
      .finally(() => { emit('close') })
}

function confirm() {
  if (props.instance) { instanceUpdate() } else { instanceCreate() }
}
onMounted(() => {
  getOrganizationList('')
  if (props.instance) {
    organizations.value = []
    role.value = {role: props.instance.role}
    for (const [key, value] of Object.entries(props.instance)) {
        if (key ==='organizations') {
          for (const k of Object.entries(toRaw(value))) {
            organizations.value.push(k[1])
          }
        }
        else {
          instanceData[`${key}`] = value;
        }
      }
  }
})


</script>


<template>
  <main-modal @confirm="confirm()" @cancel="emit('close')" >
    <template v-if="instance" v-slot:title>Edit employee</template>
    <template v-else v-slot:title>Add employee</template>
    <div class="modal__content">
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Employee first name" v-model="instanceData.first_name">
        <label>Name</label>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="text" placeholder="Email" v-model="instanceData.email">
        <label>Email</label>
      </div>
      <div class="pb-3 form-floating select-wrapper" >
        <multiselect
            selectLabel=""
            deselectLabel=""
            placeholder="Change employee role"
            v-model="role"
            :options="roleChoices"
            label="role"
        >
        </multiselect>
      </div>
      <div class="pb-3 form-floating select-wrapper" v-if="organizationList && props.userRole === 'administrator'">
        <multiselect
            :multiple="true"
            :search="true"
            :strict="false"
            :hide-selected="true"
            placeholder="Change employee organization"
            v-model="organizations"
            :options="organizationList.results"
            label="name"
        >
        </multiselect>
      </div>
      <div class="pb-3 form-floating">
        <input class="form-control" type="password" placeholder="Email" v-model="instanceData.password">
        <label>Password</label>
      </div>
    </div>
  </main-modal>
</template>


<style scoped>

</style>