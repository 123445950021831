/* eslint-disable */
import session from './session';

export default {
    getControlUnitList(filter) {
        return session.get(`/admin/v1/control_unit/${filter}`);
    },
    getControlUnit(id) {
        return session.get(`/admin/v1/control_unit/${id}/`);
    },
    deleteControlUnit(id) {
        return session.delete(`/admin/v1/control_unit/${id}/`);
    },
    createControlUnit(data) {
        return session.post(`/admin/v1/control_unit/`, data);
    },
    updateControlUnit(id, data) {
        return session.patch(`/admin/v1/control_unit/${id}/`, data );
    },
};