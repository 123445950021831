/* eslint-disable */
import session from './session';

export default {
    getModelList(filter) {
        return session.get(`/admin/v1/device_model/${filter}`);
    },
    getModel(id) {
        return session.get(`/admin/v1/device_model/${id}/`);
    },
    createModel(data) {
        return session.post(`/admin/v1/device_model/`, data);
    },
    updateModel(id, data) {
        return session.patch(`/admin/v1/device_model/${id}/`, data);
    },
    deleteModel(id) {
        return session.delete(`/admin/v1/device_model/${id}/`);
    },
};