<script setup>
import TableCard from "@/components/panels/TableCard.vue";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router"
import {copyToClipBoard, errorNotify, successNotify} from "@/mixins";
import {useStore} from "vuex";
import OrganizationModal from "@/components/modals/new_locker/OrganizationModal.vue";
import AddressModal from "@/components/modals/new_locker/AddressModal.vue";
import ControlUnitModal from "@/components/modals/new_locker/ControlUnitModal.vue";
import InformationModal from "@/components/modals/new_locker/InformationModal.vue";
import {useLoading} from "vue-loading-overlay";
import CellModal from "@/components/modals/new_locker/CellModal.vue";
import Multiselect from "vue-multiselect";

const store = useStore()
const router = useRouter()
const route = useRoute()
const $loading = useLoading({});
const getInstanceError = computed(() => store.getters['locker/lockerDataError'])
const updateInstanceError = computed(() => store.getters['locker/lockerUpdateError'])
const instance = computed(() => store.getters['locker/lockerData'])
const lockerKey = computed(() => store.getters['locker/lockerKey'])
const getInstance = (val) => store.dispatch('locker/getLocker', val)
const updateInstance = (val) => store.dispatch('locker/updateLocker', val)
const getLockerList = (val) => store.dispatch('locker/getLockerList', val)
const getLockersStatistic = (val) => store.dispatch('statistic/getLockersStatistic', val)


const showOrganizationModal = ref(false)
const showInformationModal = ref(false)
const showControlUnitModal = ref(false)
const showAddressModal = ref(false)
const showCellModal = ref(false)
const showLockerKeyModal = ref(false)
function setOrganization(instance) {
  locker.value.organization = instance
}
function prepareOrganizationCard(instance) {
  let data = {'Name': instance.name, 'Address': ''}
  if (instance.address) {data['Address'] = instance.address.full_address}
  return data
}
function setInformation(instance) {
  locker.value.information = instance
}
function prepareInformationCard(instance) {
  return {
    'Serial Number': instance.sn,
    'Name': instance.name,
    'Model': instance.model.name,
    'Cells firmware version': instance.cell_firmware_version.version,
    'IP address': instance.ip_address
  }
}
function setControlUnit(instance) {
  locker.value.control_unit.sn = instance.sn
}
function prepareControlUnitCard(instance) {
  return {
    'Serial Number': instance.unit.sn,
    'Display size': instance.unit.screen,
    'QR code reader': instance.unit.qr_reader,
    'NFC scanner': instance.unit.nft_reader,
    'Firmware version': instance.head_firmware_version.version
  }
}
function setAddress(instance) {
  locker.value.address = instance
}
function prepareAddressCard(instance) {
  let data = {'Country': instance.country, 'City': '', 'Address': instance.address}
  if (instance.city) {data['City'] = instance.city.name}
  return data
}
function setCell(instance) {
  let is_occupied = false
  if (locker.value.cells_list[instance.number - 1]) {
    is_occupied = locker.value.cells_list[instance.number - 1].is_occupied
  }
  locker.value.cells_list[instance.number - 1] = {
    number: instance.number,
    address: Number(instance.address),
    is_broken: instance.is_broken,
    cleaning_required: instance.cleaning_required,
    is_active: instance.is_active,
    is_occupied: is_occupied
  }
}

const lockerStatuses = ['online', 'offline', 'maintenance', 'error']

const locker = ref({
  key: lockerKey,
  organization: {},
  information: {
    sn: '',
    name: '',
    model: {},
    ip_address: '',
    cell_firmware_version: ''
  },
  control_unit: {
    unit: '',
    head_firmware_version: '',
  },
  address: {},
  cell: {
    number: '',
    address: '',
    is_active: true,
    is_broken: false,
    cleaning_required: false,
    is_occupied: ''
  },
  cells_list: [],
  in_sync: false,
  battery_level: null,
  status: '',
})

function goBack() {
  getLockerList('')
  getLockersStatistic()
  router.push({ name: 'lockers' })
}

function openCellModal(number) {
  if (locker.value.cells_list[number - 1] !== undefined) {
    locker.value.cell = locker.value.cells_list[number - 1]
  } else {
    locker.value.cell = {
    number: number,
    address: '',
    is_active: true,
    is_broken: false,
    cleaning_required: false,
  }}
  showCellModal.value = true
}

function instanceUpdate() {
  let cells_list = []
  locker.value.cells_list.forEach( (element) => {
      cells_list.push(element)
  });

  let data = {
    name: locker.value.information.name,
    organization: locker.value.organization.id,
    address: locker.value.address.id,
    model: locker.value.information.model.code,
    control_unit: locker.value.control_unit.sn,
    cells_list: cells_list,
    status: locker.value.status,
    ip_address: locker.value.information.ip_address,
    cell_firmware_version: locker.value.information.cell_firmware_version.id,
    head_firmware_version: locker.value.control_unit.head_firmware_version.id
  }
  updateInstance({data: data, id: locker.value.information.sn })
      .then(() => {
        if (!updateInstanceError.value) { successNotify("Locker updated successfully!") }
        else { errorNotify(updateInstanceError.value) }
      })
      .finally(goBack)
}

function getInitialData() {
  const loader = $loading.show({});
  getInstance(route.params.slug)
      .then(() => {
        if (!getInstanceError.value) {
          locker.value.organization = instance.value.organization
          locker.value.information.sn = instance.value.sn
          locker.value.information.name = instance.value.name
          locker.value.information.model = instance.value.model
          locker.value.information.ip_address = instance.value.ip_address
          locker.value.information.cell_firmware_version = instance.value.cell_firmware_version
          locker.value.control_unit.unit = instance.value.control_unit
          locker.value.control_unit.head_firmware_version = instance.value.head_firmware_version
          locker.value.address = instance.value.address
          locker.value.in_sync = instance.value.in_sync
          locker.value.battery_level = instance.value.battery_level
          locker.value.status = instance.value.status
          instance.value.cells.forEach( (element) => {
            locker.value.cells_list[element.number - 1] = {
              number: element.number,
              address: element.address,
              cleaning_required: element.cleaning_required,
              is_broken: element.is_broken,
              is_active: element.is_active,
              is_occupied: element.is_occupied,
            }
          });
        }
        else { errorNotify(getInstanceError.value) }
      })
     .finally(() => {loader.hide()});
}

onMounted(() => {
  getInitialData()
})

</script>

<template>
  <section class="device-detail">
    <vue-final-modal
        v-if="showLockerKeyModal"
        v-model="locker.key"
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal-content create-update-modal">

      <div class="modal__action pt-3">
        <p>Locker API key</p>
        {{locker.key}}
        <a class="ps-5" @click="copyToClipBoard(locker.key)" href="#">copy</a>
        <div class="pt-4">
          <button class="button w-100" @click="showLockerKeyModal=false">OK</button>
        </div>
      </div>

    </vue-final-modal>
    <OrganizationModal
        @close="showOrganizationModal=false"
        @setOrganization="setOrganization"
        v-model="showOrganizationModal"
        :instance="locker.organization"
        v-if="showOrganizationModal"/>
    <InformationModal
        @close="showInformationModal=false"
        @setInformation="setInformation"
        :instance="locker.information"
        v-model="showInformationModal"
        v-if="showInformationModal"/>
    <ControlUnitModal
        @close="showControlUnitModal=false"
        @setControlUnit="setControlUnit"
        :instance="locker.control_unit"
        v-model="showControlUnitModal"
        v-if="showControlUnitModal"/>
    <AddressModal
        @close="showAddressModal=false"
        @setAddress="setAddress"
        :instance="locker.address"
        v-model="showAddressModal"
        v-if="showAddressModal"/>
    <CellModal
        @close="showCellModal=false"
        @setCell="setCell"
        :cell="locker.cell"
        v-model="showCellModal"
        v-if="showCellModal"/>

    <div class="row pb-3">
      <div class="col">
        <h1>Locker details</h1>
      </div>
      <div class="col text-end">
        <div class="d-inline text-end pe-5" @click="router.push({ name: 'lockers' })"><a href="#">Cancel</a></div>
        <div class="d-inline" @click="instanceUpdate"><button>Save</button></div>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <TableCard
            @click="showOrganizationModal=true"
            :item="prepareOrganizationCard(locker.organization)"
            :icon-folder="'lockers'"
            :icon-name="'organization'"
            :name="'Organization'"/>
        <TableCard
            @click="showInformationModal=true"
            :item="prepareInformationCard(locker.information)"
            :icon-folder="'lockers'"
            :icon-name="'information'"
            :name="'Locker information'"/>
        <TableCard
            @click="showControlUnitModal=true"
            :item="prepareControlUnitCard(locker.control_unit)"
            :icon-folder="'lockers'"
            :icon-name="'control_unit'"
            :name="'Control unit'"/>
        <TableCard
            @click="showAddressModal=true"
            :item="prepareAddressCard(locker.address)"
            :icon-folder="'lockers'"
            :icon-name="'address'"
            :name="'locker address'"/>
      </div>

      <div class="col-8">
        <div class="main-card">
        <div class="card-content-wrapper">
          <div class="d-flex w-100">
            <div>
              <h3>Locker and its cells state</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="locker">
                <div class="cell" v-for="(value, key) in locker.information.model.cells_map" :key="key">
                  <div :class="value">
                    <div v-if="locker.cells_list[key -1] && locker.cells_list[key -1].is_active && !locker.cells_list[key -1].is_broken"
                         class="active-cell d-flex h-100" @click="openCellModal(key)">
                       <img class="icon" :src="require(`@/assets/icon/lockers/edit_icon.png`)" alt="">
                      <div v-if="locker.cells_list[key -1].cleaning_required" class="w-100 text-end">
                        <img class="icon pe-3" :src="require(`@/assets/icon/lockers/cleaning.png`)" alt="">
                      </div>
                      <div v-if="locker.cells_list[key -1].is_occupied" class="w-100 text-end">
                        <img class="icon pe-3" :src="require(`@/assets/icon/lockers/parcel.png`)" alt="">
                      </div>
                    </div>
                    <div v-else-if="locker.cells_list[key -1] && locker.cells_list[key -1].is_broken"
                         class="inactive-cell d-flex h-100" @click="openCellModal(key)">
                      <img class="icon" :src="require(`@/assets/icon/lockers/edit_icon.png`)" alt="">
                      <div v-if="locker.cells_list[key -1].cleaning_required" class="w-100 text-end">
                        <img class="icon pe-3" :src="require(`@/assets/icon/lockers/cleaning.png`)" alt="">
                      </div>
                    </div>
                    <div class="text-center" v-else-if="parseInt(key) === locker.information.model.main_cell_number">
                      HEAD
                    </div>
                    <div v-else class="d-flex h-100" @click="openCellModal(key)">
                      <img class="icon"
                           @click="openCellModal(key)" :src="require(`@/assets/icon/lockers/edit_icon.png`)" alt="" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card-content-wrapper">
                <h3>{{ locker.information.name }}</h3>
                <div class="row">
                  <div class="col">
                    <div v-if="locker.in_sync">
                      <img class="icon" :src="require(`@/assets/icon/lockers/online.png`)" alt=""> Synced
                    </div>
                    <div v-else>
                      <img class="icon" :src="require(`@/assets/icon/lockers/offline.png`)" alt=""> Out of sync
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="locker.in_sync && locker.battery_level">
                      <img class="icon" :src="require(`@/assets/icon/lockers/battery.png`)" alt="">
                      {{locker.battery_level}}
                    </div>
                    <div v-else>
                      <img class="icon" :src="require(`@/assets/icon/lockers/battery.png`)" alt=""> N/A
                    </div>
                  </div>

                  <hr class="my-3">
                  <p>Cells state</p>

                  <div class="d-flex"> <div class="info-badge active-badge">Active</div></div>
                  <div class="d-flex"> <div class="info-badge broken-badge">Broken</div></div>
                  <div class="d-flex">
                    <img class="icon pe-3" :src="require(`@/assets/icon/lockers/parcel.png`)" alt="">
                    <div class="info-badge occupied-badge">Occupied</div>
                  </div>
                  <div class="d-flex">
                    <img class="icon pe-3" :src="require(`@/assets/icon/lockers/cleaning.png`)" alt="">
                    <div class="info-badge cleaning-badge">Cleaning required</div>
                  </div>
                </div>

                <hr class="my-3">
                <p>Locker status</p>
                <div class="pb-3 form-floating select-wrapper">
                  <multiselect
                      selectLabel=""
                      deselectLabel=""
                      placeholder="Change locker status"
                      v-model="locker.status"
                      :options="lockerStatuses"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.active-cell {
  background: #D1FADF;
  .icon {
    max-height: 18px;
  }

}
.inactive-cell {
  background: #FECDCA;
}
.icon {
    margin: 5px 2px 0 5px;
    max-height: 18px;
  }
.icon-big {
  margin: 5px 2px 0 5px;
  max-height: 26px;
}
.locker {
  padding: 30px 0 0 50px;
  margin-bottom: 30px;
  max-width: max-content;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.cell {
  cursor: pointer;
  margin: 5px 5px 0 0;
  width: 114px;
  border: 1px solid #98A2B3;
  background: #F2F4F7;
}
.S {
  height: 32px;
}
.M {
  height: 64px;
}
.L {
  height: 96px;
}
.XL {
  height: 128px;
}
.info-badge {
  margin-bottom: 5px;
  padding: 5px 10px 5px 10px;
  border-radius: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.active-badge {
   background-color: #ECFDF3;
   color: #027A48;
}
.broken-badge {
  background-color: #FEE4E2;
  color: #B42318;
}
.occupied-badge {
  background-color: #F4F3FF;
  color: #5925DC;
}
.cleaning-badge {
  background-color: #FFFAEB;
  color: #B54708;
}
</style>