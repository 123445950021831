/* eslint-disable */
import session from './session';

export default {
    getAppCustomerList(filter) {
        return session.get(`/admin/v1/app_customer/${filter}`);
    },
    getAppCustomer(id) {
        return session.get(`/admin/v1/app_customer/${id}/`);
    },
    createAppCustomer(data) {
        return session.post(`/admin/v1/app_customer/`, data);
    },
    updateAppCustomer(id, data) {
        return session.put(`/admin/v1/app_customer/${id}/`, data );
    },
    deleteAppCustomer(id) {
        return session.delete(`/admin/v1/app_customer/${id}/`);
    },
};