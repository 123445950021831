/* eslint-disable */
import session from './session';

export default {
    getAddressList(filter) {
        return session.get(`/admin/v1/address/${filter}`);
    },
    getAddress(id) {
        return session.get(`/admin/v1/address/${id}/`);
    },
    deleteAddress(id) {
        return session.delete(`/admin/v1/address/${id}/`);
    },
    createAddress(data) {
        return session.post(`/admin/v1/address/`, data);
    },
    updateAddress(id, data) {
        return session.patch(`/admin/v1/address/${id}/`, data );
    },
};