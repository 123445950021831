/* eslint-disable */
import session from './session';

export default {
    getOrganizationList(filter) {
        return session.get(`/admin/v1/organization/${filter}`);
    },
    getOrganization(id) {
        return session.get(`/admin/v1/organization/${id}/`);
    },
    deleteOrganization(id) {
        return session.delete(`/admin/v1/organization/${id}/`);
    },
    createOrganization(data) {
        return session.post(`/admin/v1/organization/`, data);
    },
    updateOrganization(id, data) {
        return session.patch(`/admin/v1/organization/${id}/`, data );
    },
};