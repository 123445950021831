<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore()
const currentUserData = computed(() => store.getters['user/currentUserData'])

</script>

<template>
  <div class="dropdown">
    <button class="btn button dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.99967 10.334C2.4417 10.334 2.86562 10.5096 3.17819 10.8221C3.49075 11.1347 3.66634 11.5586 3.66634 12.0007C3.66634 12.4427 3.49075 12.8666 3.17819 13.1792C2.86562 13.4917 2.4417 13.6673 1.99967 13.6673C1.55765 13.6673 1.13372 13.4917 0.821163 13.1792C0.508603 12.8666 0.333008 12.4427 0.333008 12.0007C0.333008 11.5586 0.508603 11.1347 0.821163 10.8221C1.13372 10.5096 1.55765 10.334 1.99967 10.334ZM1.99967 5.33398C2.4417 5.33398 2.86562 5.50958 3.17819 5.82214C3.49075 6.1347 3.66634 6.55862 3.66634 7.00065C3.66634 7.44268 3.49075 7.8666 3.17819 8.17916C2.86562 8.49172 2.4417 8.66732 1.99967 8.66732C1.55765 8.66732 1.13372 8.49172 0.821163 8.17916C0.508603 7.8666 0.333008 7.44268 0.333008 7.00065C0.333008 6.55862 0.508603 6.1347 0.821163 5.82214C1.13372 5.50958 1.55765 5.33398 1.99967 5.33398ZM1.99967 0.333984C2.4417 0.333984 2.86562 0.509579 3.17819 0.82214C3.49075 1.1347 3.66634 1.55862 3.66634 2.00065C3.66634 2.44268 3.49075 2.8666 3.17819 3.17916C2.86562 3.49172 2.4417 3.66732 1.99967 3.66732C1.55765 3.66732 1.13372 3.49172 0.821163 3.17916C0.508603 2.8666 0.333008 2.44268 0.333008 2.00065C0.333008 1.55862 0.508603 1.1347 0.821163 0.82214C1.13372 0.509579 1.55765 0.333984 1.99967 0.333984Z" fill="#101828"/>
      </svg>
    </button>
    <ul class="dropdown-menu">
      <li><a @click="$emit('openModal')" class="dropdown-item"  href="#">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.16667 13.8332H3.33333L10.5208 6.64567L9.35417 5.479L2.16667 12.6665V13.8332ZM14.0833 5.43734L10.5417 1.93734L11.7083 0.770671C12.0278 0.451226 12.4203 0.291504 12.8858 0.291504C13.3508 0.291504 13.7431 0.451226 14.0625 0.770671L15.2292 1.93734C15.5486 2.25678 15.7153 2.64234 15.7292 3.094C15.7431 3.54511 15.5903 3.93039 15.2708 4.24984L14.0833 5.43734ZM1.33333 15.4998C1.09722 15.4998 0.899445 15.4198 0.74 15.2598C0.58 15.1004 0.5 14.9026 0.5 14.6665V12.3123C0.5 12.2012 0.520833 12.0937 0.5625 11.9898C0.604167 11.8854 0.666667 11.7915 0.75 11.7082L9.33333 3.12484L12.875 6.6665L4.29167 15.2498C4.20833 15.3332 4.11472 15.3957 4.01083 15.4373C3.90639 15.479 3.79861 15.4998 3.6875 15.4998H1.33333ZM9.9375 6.06234L9.35417 5.479L10.5208 6.64567L9.9375 6.06234Z" fill="#667085"/>
        </svg>
        edit
      </a></li>
      <li v-if="currentUserData.role ==='administrator'"><a @click="$emit('del')" class="dropdown-item" href="#">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.83337 15.5C2.37504 15.5 1.98282 15.3369 1.65671 15.0108C1.33004 14.6842 1.16671 14.2917 1.16671 13.8333V3C0.930596 3 0.732541 2.92028 0.572541 2.76083C0.413096 2.60083 0.333374 2.40278 0.333374 2.16667C0.333374 1.93056 0.413096 1.7325 0.572541 1.5725C0.732541 1.41306 0.930596 1.33333 1.16671 1.33333H4.50004C4.50004 1.09722 4.58004 0.899167 4.74004 0.739167C4.89948 0.579722 5.09726 0.5 5.33337 0.5H8.66671C8.90282 0.5 9.10087 0.579722 9.26087 0.739167C9.42032 0.899167 9.50004 1.09722 9.50004 1.33333H12.8334C13.0695 1.33333 13.2673 1.41306 13.4267 1.5725C13.5867 1.7325 13.6667 1.93056 13.6667 2.16667C13.6667 2.40278 13.5867 2.60083 13.4267 2.76083C13.2673 2.92028 13.0695 3 12.8334 3V13.8333C12.8334 14.2917 12.6703 14.6842 12.3442 15.0108C12.0175 15.3369 11.625 15.5 11.1667 15.5H2.83337ZM2.83337 3V13.8333H11.1667V3H2.83337ZM4.50004 11.3333C4.50004 11.5694 4.58004 11.7672 4.74004 11.9267C4.89948 12.0867 5.09726 12.1667 5.33337 12.1667C5.56948 12.1667 5.76754 12.0867 5.92754 11.9267C6.08698 11.7672 6.16671 11.5694 6.16671 11.3333V5.5C6.16671 5.26389 6.08698 5.06583 5.92754 4.90583C5.76754 4.74639 5.56948 4.66667 5.33337 4.66667C5.09726 4.66667 4.89948 4.74639 4.74004 4.90583C4.58004 5.06583 4.50004 5.26389 4.50004 5.5V11.3333ZM7.83337 11.3333C7.83337 11.5694 7.91337 11.7672 8.07337 11.9267C8.23282 12.0867 8.4306 12.1667 8.66671 12.1667C8.90282 12.1667 9.10087 12.0867 9.26087 11.9267C9.42032 11.7672 9.50004 11.5694 9.50004 11.3333V5.5C9.50004 5.26389 9.42032 5.06583 9.26087 4.90583C9.10087 4.74639 8.90282 4.66667 8.66671 4.66667C8.4306 4.66667 8.23282 4.74639 8.07337 4.90583C7.91337 5.06583 7.83337 5.26389 7.83337 5.5V11.3333Z" fill="#667085"/>
        </svg>
        delete
      </a></li>
    </ul>
  </div>
</template>

<style scoped>
</style>